import React from "react"
import tw from "twin.macro"
import { SimpleGallery } from "../../molecules/SimpleGallery"
import { FeaturedAccyGalleryProps } from "./FeaturedAccy.d"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const FeaturedAccyGallery: React.FC<FeaturedAccyGalleryProps> = ({
  slides,
}) => {
  return (
    <section
      css={[tw`bg-black pt-0 pb-[27rem] relative md:(pb-52 pt-12 my-12)`]}
    >
      <div
        css={[
          tw`max-w-screen-xl mx-auto px-8 text-center text-white mb-10 relative z-10`,
        ]}
      ></div>
      {slides && <SimpleGallery slides={slides} />}
    </section>
  )
}

export default FeaturedAccyGallery
