import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import { Breadcrumb, BreadcrumbProps } from "./Breadcrumbs.d"

/**
 * Breadcrumb navigation with full microdata schema.
 *
 * Takes a list of Breadcrumb as prop "items".
 *
 * @component
 * @param {BreadcrumbProps} props
 * @param {Breadcrumb[]} props.items - List of breadcrumb elements
 *
 * @returns <ol></ol>
 */
const Breadcrumbs: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav aria-label="breadcrumbs">
      <ol
        css={[tw`-mx-2`]}
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        {items.map(({ label, to }, i) => {
          const isLastItem = i + 1 === items.length

          return (
            <li
              key={`breadcrumb-${i}`}
              css={[
                tw`inline-block text-sm`,
                !isLastItem && tw`border-gray-500 border-r-2 leading-none`,
              ]}
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              {isLastItem ? (
                // If last item in list, no link
                <span
                  css={[tw`text-gray-600 px-2`]}
                  itemProp="name"
                  aria-current="page"
                >
                  {label}
                </span>
              ) : (
                // Otherwise, let it be a link.
                <Link
                  to={to}
                  css={[
                    tw`text-red-400 px-2 hover:(underline) focus:(underline)`,
                  ]}
                  itemProp="item"
                >
                  <span itemProp="name">{label}</span>
                </Link>
              )}
              <meta itemProp="position" content={`${i + 1}`} />
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
