import React, { useContext, useEffect, useRef } from "react"
import { FeaturedAccyTemplate } from "./templates"
import { graphql } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import { OthersAlsoBought } from "../components/molecules/OthersAlsoBought"
import FeaturedAccyHeader from "../components/organisms/FeaturedAccessory/Header"
import FeaturedAccyGallery from "../components/organisms/FeaturedAccessory/Gallery"
import { SEO } from "../components/atoms/SEO"
import { Brochure, ToyotaCare } from "../components/organisms/Series"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import FAQ from "../components/molecules/FAQ/FAQ"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { LocationContext } from "../contexts/Location"
import useDealers from "../hooks/useDealers"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import PartAndServiceHero from "../components/organisms/PartAndServiceHero/PartAndServiceHero"
import PartInfo from "../components/organisms/Part/Info"
import PartHighlights from "../components/organisms/Part/Highlights"
import TabbedImageModule from "../components/molecules/TabbedImageModule/TabbedImageModule"

const FeaturedAccessoryTemplate: React.FC<FeaturedAccyTemplate> = ({
  data,
  location,
}: any) => {
  const { page } = data

  // Tealium
  const { handleTealView } = useTealiumView()
  const [{ dealers }] = useContext(LocationContext)
  const pageload = useRef(true)
  const { getDealerInfo } = useDealers()
  const [isOutOfArea] = useTealiumNoZip()

  useEffect(() => {
    const dealerInfo = getDealerInfo()
    /* Fire only on inital page load, and not every time dealers is updated */
    if (isOutOfArea && pageload.current) {
      pageload.current = false
      handleTealView({
        page_type: "accessories",
        tealium_event: "accessories_detail",
        page_name: "accessory detail",
        search_results: 0,
        dealer_code: "NOT SET",
        dealer_name: "NOT SET",
      })
    }

    if (pageload.current && dealers) {
      pageload.current = false
      handleTealView({
        page_type: "accessories",
        tealium_event: "accessories_detail",
        page_name: "accessory detail",
        search_results: dealers?.length,
        dealer_code: dealerInfo.dealerCodes,
        dealer_name: dealerInfo.dealerNames,
      })
    }
  }, [dealers, isOutOfArea])

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.pageTitle}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <SanitySections sections={page?.content} title={page.title} />
    </Layout>
  )
}

const SanitySections: React.FC<{ sections: any; title: string }> = ({
  sections,
  title,
}): JSX.Element => {
  return (
    <>
      {sections?.map((section: any) => {
        switch (section?._type) {
          case "featuredAccyHeader":
            return <FeaturedAccyHeader {...section} title={title} />
          case "partsAndServiceHero":
            return <PartAndServiceHero {...section} />
          case "gallery":
            return <FeaturedAccyGallery {...section} />
          case "tabbedImageModule":
            return <TabbedImageModule {...section} />
          case "brochure":
            return <Brochure {...section} />
          case "othersAlsoBoughtSection":
            return <OthersAlsoBought {...section} />
          case "dealersNearYouSection":
            return <DealersNearYou type="default" {...section} />
          case "imageWithText":
            return <ToyotaCare {...section} />
          case "faqSection":
            return <FAQ {...section} expanded showTitle={false} />
          case "imageBlocksWithDescriptionSection":
            return <ImageBlocksWithDescription section={section} />
          case "partInfoSection":
            return (
              <>
                <PartInfo {...section} />
                <PartHighlights {...section} />
              </>
            )
        }
      })}
    </>
  )
}

export const query = graphql`
  query FeaturedAccessoryPageQuery($id: String) {
    page: sanityPageFeaturedAccessory(id: { eq: $id }) {
      id
      language
      _type
      title
      pageTitle
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      content {
        ...FeaturedAccyHeaderData
        ...GallerySection
        ...OthersAlsoBoughtSectionData
        ...TabbedImageModuleData
        ...BrochureData
        ...ToyotaCareData
        ...DealersNearYouData
        ...FaqSectionData
        ...ImageBlocksWithDescriptionData
        ...PartsAndServiceHeroData
        ...PartInfoData
      }
    }
  }
`

export default FeaturedAccessoryTemplate
