import { useContext, useEffect, useState } from "react"
import { LocationContext } from "../../contexts/Location"

/**
 * @author Stu
 * @summary Custom hook to determine if visitor is from out of area (and therefore on the initial pageload, no zip code will have been set via IP lookup)
 *
 * @returns {array} - length of one, boolean value for "is out of area"
 */

// Resuable logic to determine is no zip code has been set
const useTealiumNoZip = () => {
  const [{ isOutOfRange }] = useContext(LocationContext)
  const [isOutOfArea, setIsOutOfArea] = useState(false)

  useEffect(() => {
    // "user_ip" property of isOutOfRange is used instead of "value" property because value is set to "false" even when user is out of area
    if (isOutOfRange?.user_ip) {
      setIsOutOfArea(true)
    }
  }, [isOutOfRange?.user_ip])

  return [isOutOfArea]
}

export default useTealiumNoZip
