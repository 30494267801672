import React, { useContext, useEffect } from "react"
import tw from "twin.macro"
import { FeaturedAccyHeaderProps } from "./FeaturedAccy.d"
import { Image } from "../../atoms/Image"
import { LocalInventoryLinks } from "../../molecules/LocalInventoryLinks"
import { LocationContext } from "../../../contexts/Location"
import { Breadcrumbs } from "../../atoms/Breadcrumbs"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import ExternalVideo from "../../molecules/VideoSection/ExternalVideo"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const FeaturedAccyHeader: React.FC<FeaturedAccyHeaderProps> = ({
  backgroundImage,
  title,
  installType,
  tagline,
  video,
  availableFor,
  description,
  highlights,
}) => {
  const [{ city, dealers }] = useContext(LocationContext)
  const { _ } = useContext(LanguageContext)
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <>
      <section
        css={[
          tw`flex h-[43rem] relative bg-gray-900`,
          tw`md:(min-h-[35rem] items-center overflow-hidden mb-8)`,
        ]}
        aria-label="Featured Accessories Section"
      >
        <div css={[tw`absolute top-0 h-[35rem]`]}>
          {backgroundImage && (
            <Image
              imageData={backgroundImage?.image}
              css={[
                tw`w-full bg-cover contrast-150 h-auto`,
                tw`md:(bg-contain bg-top)`,
              ]}
            />
          )}
          {/* BACKGROUND GRADIENT OVERLAY */}
          <div
            css={[
              tw`absolute w-full min-h-[22rem] max-h-[43rem] bg-gray-900 top-0`,
              tw`md:(h-[40rem] bottom-0 min-h-screen bg-cover)`,
              `z-index: 1;
            background: linear-gradient(
              to top, 
              rgba(34, 37, 43, 1) 15%, 
              rgba(34, 37, 43, 0.3) 30%),
              linear-gradient(
                to top, 
                rgba(34, 37, 43, .5) 54%, 
                rgba(34, 37, 43, 0) 71%)`,
              `background-size: 100% auto`,
              `
              @media (max-width: 768px) {
                background: linear-gradient(
          to top,
          rgba(34, 37, 43, 1) 10%,
          rgba(34, 37, 43, 0.3) 30%),
                background-size: contain;
              }
            `,
            ]}
          ></div>
        </div>
        <section
          css={[
            tw`grid overflow-hidden grid-cols-12 grid-rows-3 content-center grid-flow-row h-auto z-10 w-full mx-auto px-8 text-white`,
            tw`md:(gap-0 max-w-7xl px-12 grid-rows-1 mt-0)`,
            tw`xl:(px-4 min-h-[35rem])`,
          ]}
        >
          <div
            css={[
              tw`mt-44 col-start-1 col-span-12`,
              tw`sm:(mt-96 col-start-1 col-span-12)`,
              tw`md:(col-span-5 mt-0 content-center)`,
            ]}
          >
            <h1
              css={[
                tw`text-3xl tracking-widest block font-light md:(text-5xl my-5)`,
              ]}
            >
              {parseDisclaimerBlocks(title, selection =>
                modalDispatch(toggleDisclaimersModal(selection))
              )}
            </h1>
            {installType === "dealer" && (
              <span
                css={[
                  tw`bg-gray-600 rounded-full px-5 py-1 mr-4 mt-4 inline-block md:(mt-0)`,
                ]}
              >
                <span> {_("Dealer Installed")}</span>
              </span>
            )}
            <span css={[tw`py-4 block md:(inline py-0)`]}>{availableFor}</span>
            <p
              css={[
                tw`text-base mt-10`,
                tw`md:(font-light text-2xl tracking-widest my-10 leading-normal)`,
                tw`xl:(text-3xl)`,
              ]}
            >
              {tagline}
            </p>
          </div>
          <div
            css={[
              tw`col-start-1 row-start-3 col-span-12 mt-20`,
              tw`md:(col-start-8 row-start-1 col-span-5 text-white flex items-center mt-20)`,
            ]}
          >
            <LocalInventoryLinks
              city={city}
              dealerCount={dealers?.length}
              hideInventory={true}
              additionalLinkText={title.replace("Toyota", "")}
              light
              analyticsId="hero"
            />
          </div>
        </section>
      </section>

      <section
        css={[
          tw`grid overflow-hidden grid-cols-12 grid-rows-2 grid-flow-row h-auto z-10 w-full mx-auto mt-0 p-4`,
          tw`md:(relative max-w-7xl py-4 px-12 grid-rows-1 -mt-20 gap-4)`,
          tw`md:(p-4)`,
        ]}
        aria-label="Featured Accessories Highlights and Video Section"
      >
        {Array.isArray(highlights) && highlights?.length > 0 && (
          <div
            css={[
              tw`bg-gray-200 rounded-2xl p-6 pr-4 col-start-1 row-start-2 col-span-12 mt-4`,
              tw`md:( row-start-1 col-span-7 p-14 mt-0)`,
            ]}
          >
            <ul
              css={[
                tw`text-black ml-4`,
                tw`md:(grid overflow-visible grid-cols-2 grid-rows-1 gap-2 grid-flow-row w-auto h-auto)`,
                tw`xl:(gap-x-6)`,
              ]}
            >
              {highlights.map((highlight: any) => (
                <li
                  css={[
                    tw`list-disc list-outside text-base font-semibold mr-4`,
                  ]}
                >
                  {highlight}
                </li>
              ))}
            </ul>
          </div>
        )}
        {video && (
          <div
            css={[
              tw`row-start-1 h-[240px] col-span-12 mt-4`,
              tw`md:(col-start-8 row-start-1 col-span-5 mt-0 h-auto)`,
            ]}
          >
            <ExternalVideo
              videoSectionHeading=""
              videoSectionVideo={video}
              videoCss={[tw`rounded-2xl w-full h-full object-[16/9]`]}
              extraTealiumValues={{
                full_page_name: `${
                  typeof window !== "undefined" ? window.location.hostname : ""
                }:accessories:accessory detail`,
                page_name: "accessory detail",
                page_type: "accessories",
                page_url: `${
                  typeof window !== "undefined" ? window.location.href : ""
                }`,
              }}
            />
          </div>
        )}
      </section>

      <section
        css={[
          tw`grid overflow-hidden grid-cols-12 grid-rows-1 grid-flow-row h-auto z-10 w-full mx-auto px-8`,
          tw`md:(max-w-7xl px-12 grid-rows-1) xl:(px-4)`,
        ]}
        aria-label="Featured Accessories Description"
      >
        <div
          css={[tw`hidden md:(block col-start-1 row-start-1 col-span-12 py-8)`]}
        >
          <Breadcrumbs
            items={[
              { label: "Accessories", to: "/accessories" },
              { label: `${title}`, to: "/" },
            ]}
          />
        </div>
        <div
          css={[
            tw`col-start-1 row-start-1 my-4 col-span-12`,
            tw`md:(col-start-1 row-start-2 col-span-7 my-0 mb-20)`,
          ]}
        >
          <p>{description}</p>
        </div>
      </section>
    </>
  )
}

export default FeaturedAccyHeader
