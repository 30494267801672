import React, { useContext } from "react"
import tw from "twin.macro"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { LanguageContext } from "../../../contexts/Language"
import { Accordion } from "../../atoms/Accordion"
import BlockContent from "../../atoms/BlockContent"
import { Toyota } from "../../atoms/Logo"
import { FaqProps } from "./FAQ.d"
import { parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const FAQ: React.FC<FaqProps> = ({
  faqs,
  title,
  expanded = false,
  showTitle = true,
}) => {
  const { _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)
  return (
    <>
      <section
        aria-label="FAQs Section"
        css={[tw`mx-8 my-20`, tw`lg:(max-w-5xl mx-auto my-20)`]}
      >
        {showTitle && (
          <h3
            css={[
              tw`flex items-center text-xl justify-between font-semibold mb-4`,
              tw`lg:(text-xl items-center justify-start)`,
            ]}
          >
            <span css={[tw`flex items-center`]}>
              <Toyota showText={false} css={[tw`mr-3 mb-1`]} />
              {title !== undefined ? title : null}
            </span>
            <span css={[tw`font-light tracking-widest pl-3 text-2xl`]}>
              {_("FAQs")}
            </span>
          </h3>
        )}
        <div css={[tw`border-b border-gray-500`]}>
          {faqs?.map((faq, i) => (
            <Accordion
              expanded={expanded}
              label={parseDisclaimerBlocks(faq?.question, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
              css={[
                tw`border-t items-start border-gray-500 px-5 pl-0 py-6 mt-0`,
                tw`md:(px-5 py-6 mt-0)`,
              ]}
              analytics-id={`accordion:faq:${i + 1}`}
            >
              <ul css={[tw`block text-left mr-7`]}>
                <li css={[tw`ml-0 mb-4`, tw`md:(ml-6 mb-6)`]}>
                  {faq.answer && <BlockContent data={faq.answer} />}
                </li>
              </ul>
            </Accordion>
          ))}
        </div>
        <div id="qualtrics-faq-question"></div>
      </section>
    </>
  )
}

export default FAQ
